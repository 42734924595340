.init-Login__Wrapper {
  min-height: 100vh;
  position: relative;
  padding-bottom: 9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;
}

.init-LoginPage {
  max-width: 45%;
  margin: 0rem auto;
  padding: 0;
}

.disable{
  pointer-events: none;
  opacity: 0.4;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inte-card.inte-card--bordered.Custom__Card--Logo {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.inte-card.inte-card--bordered.Custom__Card--Logo-Border {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 4px solid #c82121;
}

.inte-Modal-Footer__FooterContent
  .inte-flex
  .inte-flex__item:last-child
  .inte-btn {
  background-color: #008060;
}

@media only screen and (min-width: 901px) and (max-width: 1500px) {
  .init-LoginPage {
    max-width: 65%;
  }
}

@media only screen and (max-width: 900px) {
  .init-LoginPage {
    max-width: 100%;
    padding: 0 2.4rem;
  }
}

.custom-404{
  background-color:#ffffff
}
